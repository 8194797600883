import React from 'react'
import './About.css'
import Footer from "../../components/footer/footer";

function About() {
    return(
        <>
            <div className="about">
                <div className="container">
                    <p>Представьте:<br/>
                        Вы приходите в магазин, видите скидку по карте на товар, но самой карты у вас нет. Без карты получить скидку не выйдет...грустно.
                        Именно для таких случаев и создан CardBot.</p>
                    <p>CardBot - бот Вконтакте, в базе которого находится более 40 скидочных карт, которые он предоставляет одним из способов : </p>
                    <div className="aboutVariant">
                        <div>
                            <p>Через личные сообщения</p>
                            <img src="about_message.svg" alt="message"/>
                        </div>
                        <div>
                            <p>Через беседу</p>
                            <img src="about_chat.svg" alt="chat"/>
                        </div>
                    </div>
                    <p>Бот способен анализировать сообщения и общаться. Если его добавить в беседу вк, дать доступ ко всем сообщениям, то он сможет общаться или скидывать карты в ответ на просьбы “Есть у кого карта перекрестка?” и подобные.</p>
                    <p>Бот способен обучаться новым словам, песням, шуткам и применять их в диалоге, что делает его приятным бонусом в беседе для любой компании людей.</p>
                    <p>CardBot предоставляет возможность создавать личный кабинет для пользователей и добавлять боту собственные карты и команды (ответные действия на ваши сообщения).</p>
                    <p>CardBot - это некоммерческий проект, который призван помогать всем желающим.</p>
                    <p>Если Вам как-то помог этот бот, то вы можете вступить в группу для поддержки проекта. Автору важно знать, что проект нужен кому-то)</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About;